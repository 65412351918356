















































import { Component, Prop, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

import moment from "moment";

type Post = {
  id: number;
  author: string;
  url_avatar_author?: string;
  url_upload?: string;
  translation: {
    name: string;
    description: string;
  };
};

@Component({
  filters: {
    created_at_from_now(value, appLanguage) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .fromNow();
    }
  }
})
export default class PostPage extends Vue {
  @Prop(Number) readonly postId!: number;

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView(`Post: ${this.postId}`);
  }

  private post: null | Post = null;

  created() {
    this.fetchPostById();
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get authorInitials() {
    return this.post?.author?.substr(0, 2).toUpperCase();
  }

  get urlAvatar() {
    return this.post?.url_avatar_author;
  }

  get urlUpload() {
    return this.post?.url_upload;
  }

  get name() {
    return this.post?.translation?.name;
  }

  get description() {
    return this.post?.translation?.description;
  }

  fetchPostById() {
    this.$store
      .dispatch("posts/fetchById", this.postId)
      .then(({ data }) => (this.post = data.data));
  }
}
